<template>
  <div>
    <div>
      <v-row v-if="!data_loaded">
        <v-col v-for="(a, i) in  Array(8)" cols="6" md="3" sm="6" :key="a">
          <v-skeleton-loader :key="a" type="article"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>

    <v-row v-if="data_loaded">
      <v-col cols="6" md="3" sm="6" v-for="(stat, i) in stats" :key="i">
        <v-card>
          <v-card-text>
            <h3 class="mb-4">{{ stat.title }}</h3>
            <v-divider></v-divider>
            <div class="d-flex justify-space-between mt-3">
              <h3>{{ stat.total != null ? stat.total + "₪" : "__" }}</h3>
              <h3>{{ stat.count }}</h3>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Stats",
  props: ["stats", "data_loaded"],
  data() {
    return {};
  },
};
</script>
