<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>

      <div class="ps-5 page_content grow">
        <Stats :data_loaded="data_loaded" :stats="stats"></Stats>
      </div>
    </div>
  </v-container>
</template>

<script>
import Stats from "@/components/dashboard/Stats";

export default {
  name: "dashboard",
  data() {
    return {
      data_loaded: false,
      stats: [],
    };
  },
  components: {
    Stats,
  },
  methods: {
    dashboard() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "dashboard",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.stats = res.data.data;
          console.log(res);
        });
    },
  },
  mounted() {
    this.dashboard();
  },
};
</script>
